import styled from "styled-components";
import { Rem, Colors, BP } from "../../commons/Theme";

export const StyledTechCarouselContainer = styled.div`
`;

export const StyledTechCarouselItem = styled.div`
  max-height: 400px;

  @media (${BP.ipad}) {
    max-height: 632px;
  }

  overflow: hidden;
  position: relative;
  border-radius: 20px;
`;

export const StyledText = styled.div`
  margin: ${Rem(20)} 0;

  @media (${BP.ipad}) {
    margin: ${Rem(30)} 0;
  }
`;

export const StyledBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  background: rgba(0, 0, 0, 0.4);
`;

export const StyledCard = styled.div`
  position: absolute;
  top: 20px;
  display: flex;
  padding: 0px ${Rem(30)} 0px ${Rem(30)};
  flex-direction: column;
  justify-content: start;
  font-style: normal;
  font-weight: 300;
  font-size: ${Rem(14)};
  line-height: 150%;
  color: white;
  height: 100%;
  width: 100%;

  @media (${BP.ipad}) {
    width: 50%;
    top: 80px;
    font-size: ${Rem(20)};
    padding: 0px ${Rem(60)} 0px ${Rem(60)};
    right: ${(props) => props.position === "right" ? "0px" : "auto"};
  }
`;

export const StyledLabel = styled.div`
  font-size: ${Rem(12)};
  line-height: ${Rem(23)};
  letter-spacing: ${Rem(3)};
  display: flex;
  align-items: center;
  
  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
  }
`;

export const StyledSeparetor = styled.div`
  width: 20px;
  border-bottom: 1px solid ${Colors.yellow};
  height: 0;
  margin: 20px;
`;

export const StyledTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: ${Rem(25)};
  line-height: ${Rem(28)};
  text-transform: uppercase;
  margin-top: ${Rem(20)};
  color: #FFFFFF;

  @media (${BP.ipad}) {
    margin-top: ${Rem(40)};
    font-size: ${Rem(40)};
    line-height: ${Rem(42)};
  }
`;

export const StyledCtaLabel = styled.div`
  justify-content: space-between;
  display: flex;
  align-content: center;
  padding: ${Rem(8)};
`;

export const StyledImageWrapper= styled.div`
  height: 400px;
  border-radius: 20px;
  @media (${BP.ipad}) {
    height: 100%;
    max-height: 600px;
  }
`;

export const StyledSwiperBullets = styled.div`
  text-align: center;
  padding-top: ${Rem(5)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(10)}
  }

  .swiper-pagination-bullet {
    width: ${Rem(10)};
    height: ${Rem(10)};
    margin: 0 ${Rem(8)} !important;
    border-radius: ${Rem(10)};
    background-color: ${Colors.grayDark};

    @media (${BP.ipad}) {
      width: ${Rem(16)};
      height: ${Rem(16)};
    }
  }

  .swiper-pagination-bullet-active {
    background-color: ${Colors.yellow};
  }
`;
