import React, { useContext, useEffect, useRef } from "react";
import { StyledGridRow } from "../../commons/Grid";
import { DataContext } from "../../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button } from "@3beehivetech/components";
import useNavigate from "../../hooks/useNavigate";
import { 
  StyledBackdrop,
  StyledCard,
  StyledSeparetor,
  StyledTechCarouselContainer,
  StyledTechCarouselItem,
  StyledText,
  StyledTitle,
  StyledLabel,
  StyledSwiperBullets,
  StyledImageWrapper,
  StyledCtaLabel,
} from "./style";
import Swiper, { Pagination, Autoplay } from "swiper";

const TechCarouselItem = ({ props }) => {
  const { pageImages } = useContext(DataContext);
  const imageData = pageImages.find((e) => e.path === props.image);

  if(props.hidden) return null;

  return (
    <StyledTechCarouselItem className="swiper-slide">
      {imageData && (
        <StyledImageWrapper>
          <GatsbyImage
            image={getImage(imageData)}
            alt={imageData.alt}
            style = {{ "position": "unset" }}
          >
            <StyledBackdrop/>
          </GatsbyImage>
        </StyledImageWrapper>
      )}
      <StyledCard position={props.position}>
        <StyledLabel><span>{props.index}</span><StyledSeparetor/><span>{props.label}</span></StyledLabel>
        <StyledTitle dangerouslySetInnerHTML={{ __html: props.title }}/>
        <StyledText dangerouslySetInnerHTML={{ __html: props.text }} />
        {props.to && (
          <div>
            <Button onClick={useNavigate(props.to)}>
              <StyledCtaLabel>
                <span style={{ "margin-right": "10px" }}>{props.cta}</span>
              </StyledCtaLabel>
            </Button>
          </div>)}
      </StyledCard>
    </StyledTechCarouselItem>
  );
};

Swiper.use([Pagination, Autoplay]);

const TechCarousel = ({
  ctaLabel,
  items,
}) => {
  const sliderEl = useRef();
  const sliderPaginationEl = useRef();

  useEffect(() => {
    new Swiper(sliderEl.current, {
      allowTouchMove: true,
      spaceBetween: 50,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: true,
      },
      pagination: {
        el: sliderPaginationEl.current,
        clickable: true,
      },
    });
  }, [sliderEl]);
  return (
    <StyledTechCarouselContainer id="TechCarousel">
      <StyledGridRow>
        <div id="TechCarousel" ref={sliderEl} className="swiper-container">
          <div className="swiper-wrapper">
            {items.filter(item => !item.hidden).map((item, index) => (
              <TechCarouselItem
                key={index} props={{ ...item, ctaLabel, index: `0${index + 1}` }}
              />
            ))}
          </div>
          <StyledSwiperBullets ref={sliderPaginationEl} />
        </div>
      </StyledGridRow>
    </StyledTechCarouselContainer>
  );
};

export default TechCarousel;
